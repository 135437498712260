import { ResetPassword } from "./src/ResetPassword";
import Background from "./src/Background";

export default function App() {
  return (
    <Background>
      <ResetPassword />
    </Background>
  );
}
