import { useEffect, useState } from "react";
import React, { View, StyleSheet, Pressable } from "react-native";
import { Button, Icon, Input } from "react-native-elements";
import { supabase } from "./supabase";

const PRIMARY_BUTTON_COLOR = "hsla(227, 88%, 63%, 1)";
const BUTTON_ELEVATION = 10;

function getHashParams() {
  var hashParams = {};
  var e,
    a = /\+/g, // Regex for replacing addition symbol with a space
    r = /([^&;=]+)=?([^&;]*)/g,
    d = function (s) {
      return decodeURIComponent(s.replace(a, " "));
    },
    q = window.location.hash.substring(1);

  while ((e = r.exec(q))) hashParams[d(e[1])] = d(e[2]);

  return hashParams;
}

const ResetPassword = (props) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [session, setSession] = useState(null);

  useEffect(() => {
    if (session == null) {
      // const queryString = window.location.search;
      const params = getHashParams(window.location.hash);
      supabase.auth
        .setSession({
          access_token: params.access_token,
          refresh_token: params.refresh_token,
        })
        .then((data) => {
          setSession(supabase.auth.getSession());
        })
        .catch((error) => {
          console.log("setsession error", error);
        });
    }
  });

  const [passwordHidden1, setPasswordHidden1] = useState(true);
  const [passwordHidden2, setPasswordHidden2] = useState(true);

  const updatePassword = async () => {
    if (newPassword !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }
    const { data, error } = await supabase.auth.updateUser({
      password: newPassword,
    });
    if (error) {
      if (error.toString().includes("New password should be different")) {
        alert(
          "New password should be different from your previous password. Please try again."
        );
        return;
      }
      console.log("error", error);
      console.log("data", data);
      alert(
        "Something went wrong. Please try again with a different password or contact support@brainlaserapp.com"
      );
    } else {
      alert(
        "Password successfully updated! Please return to the app to log in."
      );
    }
  };

  return (
    <View
      style={[
        {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        },
      ]}
    >
      <h1>BrainLaser Reset Password</h1>
      <View
        elevation={BUTTON_ELEVATION}
        style={[
          styles.shadow,
          (styles.verticallySpaced,
          {
            marginHorizontal: 5,
            width: 500,
            backgroundColor: "white",
            padding: 10,
            borderRadius: 10,
          }),
        ]}
      >
        <Input
          label="New Password"
          leftIcon={{ type: "font-awesome", name: "lock" }}
          rightIcon={
            <Pressable onPress={() => setPasswordHidden1(!passwordHidden1)}>
              <Icon
                name={passwordHidden1 ? "visibility" : "visibility-off"}
              ></Icon>
            </Pressable>
          }
          onChangeText={(text) => setNewPassword(text)}
          value={newPassword}
          secureTextEntry={passwordHidden1}
          placeholder="New Password"
          autoCapitalize={"none"}
          labelStyle={styles.labelStyle}
          containerStyle={styles.containerStyle}
          inputContainerStyle={styles.inputContainerStyle}
          placeholderTextColor="hsla(219, 32%, 31%, 0.8)"
        ></Input>
        <Input
          label="Confirm New Password"
          leftIcon={{ type: "font-awesome", name: "lock" }}
          rightIcon={
            <Pressable onPress={() => setPasswordHidden2(!passwordHidden2)}>
              <Icon
                name={passwordHidden2 ? "visibility" : "visibility-off"}
              ></Icon>
            </Pressable>
          }
          onChangeText={(text) => setConfirmPassword(text)}
          value={confirmPassword}
          secureTextEntry={passwordHidden2}
          placeholder="Confirm New Password"
          autoCapitalize={"none"}
          labelStyle={styles.labelStyle}
          containerStyle={styles.containerStyle}
          inputContainerStyle={styles.inputContainerStyle}
          placeholderTextColor="hsla(219, 32%, 31%, 0.8)"
        ></Input>
        <View
          elevation={BUTTON_ELEVATION}
          style={[
            styles.verticallySpaced,
            styles.shadow,
            {
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: PRIMARY_BUTTON_COLOR,
              borderRadius: 10,
              // marginBottom: 10,
            },
            styles.mt20,
          ]}
        >
          <Button
            title="Update Password"
            onPress={updatePassword}
            buttonStyle={styles.button}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 40,
    padding: 12,
  },
  verticallySpaced: {
    marginTop: 4,
    marginBottom: 8,
    // alignSelf: "stretch",
  },
  mt20: {
    marginTop: 3,
  },
  button: {
    backgroundColor: PRIMARY_BUTTON_COLOR,
    width: 200,
    borderRadius: 10,
  },
  buttonForElevation: {
    borderRadius: 10,
    backgroundColor: PRIMARY_BUTTON_COLOR,
  },
  labelStyle: {
    color: "black",
  },
  containerStyle: { paddingLeft: 0 },
  inputContainerStyle: { borderColor: "black" },
  shadow: {
    shadowRadius: 2,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.8,
  },
});

export { ResetPassword };
