import { Dimensions, View, SafeAreaView } from "react-native";
import React from "react";
import Svg, { Path, Rect } from "react-native-svg";

const BACKGROUNDBLUE1 = "hsla(190, 80%, 64%, 1)";
const BACKGROUNDBLUE2 = "hsla(190, 77%, 50%, 1)";

const Background = ({ children, customStyles }) => {
  const screenHeight = Dimensions.get("window").height;
  return (
    <View
      style={[
        customStyles,
        { backgroundColor: BACKGROUNDBLUE1, width: "100%" },
      ]}
    >
      <View
        style={{
          height: "100%",
          position: "relative",
        }}
      >
        <Svg
          height="100%"
          width="100%"
          viewBox="0 0 1440 200"
          style={{ position: "absolute" }}
        >
          <Path
            fill={BACKGROUNDBLUE2}
            d="M0,128L48,117.3C96,107,192,85,288,74.7C384,64,480,64,576,101.3C672,139,768,213,864,229.3C960,245,1056,203,1152,154.7C1248,107,1344,53,1392,26.7L1440,0L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          />
          <Rect
            x="0"
            y="320"
            width="100%"
            height={3 * screenHeight}
            fill={BACKGROUNDBLUE2}
          />
        </Svg>
        <SafeAreaView style={{ height: "100%" }}>{children}</SafeAreaView>
      </View>
    </View>
  );
};

export default Background;
